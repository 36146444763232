.join {
  text-align: center;
  color: #000;
  height: 100vh;
}

.join header img {
  margin-top: 57px;
}

.join header h1 {
  font-weight: 400;
  font-size: 32px;
  margin: 30px 20px 40px 20px;
  line-height: 38.4px;
  letter-spacing: -0.408px;
}

.join header p.joinDescription {
  font-family: TitlingGothicLight, San Francisco, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.408px;
  margin: 0px 30px 60px 30px;
}

.join .phoneForm .phoneFormInput {
  display: flex;
  justify-content: center;
}

.join .store-links {
  margin-top: 68px;
}

.join .store-links div svg {
  cursor: pointer;
}


.join .store-links div.play-store {
  margin-top: 40px;
}

.join .phoneForm p.phoneFormDescription,
.join section .download-description p {
  font-family: TitlingGothicLight, San Francisco, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  letter-spacing: -0.41px;
  margin: 60px 20px 30px 20px;
}

.join section .download-description {
  margin-top: 68px;
}

.join.is-desktop-or-laptop section .download-description p {
  font-weight: 500;
  font-size: 21px;
  line-height: 24.2px;
}

.join .phoneForm button {
  background: #000;
  color: #fff;
  width: 335px;
  height: 60px;
  border-radius: 15px;
  font-family: TitlingGothicMedium, San Francisco, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.6px;
  letter-spacing: -0.41px;
}

.join .phoneForm .PhoneInput {
  border: 2px solid #000;
  border-radius: 15px;
  width: 335px;
  height: 60px;
}

.join .phoneForm .PhoneInput.PhoneInputError {
  border-color: #ff532c;
}

.join .phoneForm .PhoneInput .PhoneInputInput {
  border: none;
  font-family: TitlingGothicLight, San Francisco, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.6px;
  letter-spacing: -0.41px;
}

.join .phoneForm p.phone-input-errors {
  font-family: TitlingGothicLight, San Francisco, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21.6px;
  letter-spacing: -0.41px;
  margin: 10px 10px -10px 10px;
  color: #ff532c;
}

.join .phoneForm .PhoneInput .PhoneInputInput:focus {
  outline: none;
}

.join .phoneForm .PhoneInputCountry {
  padding: 10px 0 10px 20px;
}

.join footer {
  padding-bottom: 57px;
}

.join footer div.findMore {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  letter-spacing: -0.41px;
  margin: 30px 20px 80px 20px;
}

.join footer div.findMore span {
  cursor: pointer;
}

.join footer div.findMore span i {
  margin-left: 5px;
  margin-bottom: 2px;
}

.join footer section div p {
  font-weight: 500;
  font-size: 24px;
  line-height: 28.8px;
  letter-spacing: -0.41px;
  margin: 0px 20px 80px 20px;
}

.join footer img.description-image {
  max-width: 100%;
  max-height: 100%;
}

.join.is-desktop-or-laptop {
  margin: 0 auto;
  max-width: 960px;
}

.join.is-desktop-or-laptop header img {
  width: 90px;
  height: 90px;
}
.join.is-desktop-or-laptop header h1 {
  font-size: 48px;
  line-height: 54.4px;
}
.join.is-desktop-or-laptop header p.joinDescription {
  font-size: 32px;
}
.join.is-desktop-or-laptop .phoneForm p.phoneFormDescription {
  font-size: 20px;
}
.join.is-desktop-or-laptop .phoneForm button {
  font-size: 22px;
}
.join.is-desktop-or-laptop .phoneForm .PhoneInput .PhoneInputInput {
  font-size: 22px;
  line-height: 36px;
}
.join.is-desktop-or-laptop .phoneForm p.phone-input-errors {
  font-size: 18px;
}
.join.is-desktop-or-laptop footer div.findMore {
  font-size: 20px;
}
.join.is-desktop-or-laptop footer section div p {
  font-size: 22px;
}

.join.is-desktop-or-laptop footer img.description-image {
  max-width: 30%;
  max-height: 30%;
}
