@import "reset-css";

@font-face {
  font-family: TitlingGothicBlack;
  src: url("./fonts/TitlingGothicBlack.otf");
}

@font-face {
  font-family: TitlingGothicMedium;
  src: url("./fonts/TitlingGothicMedium.otf");
}

@font-face {
  font-family: TitlingGothicLight;
  src: url("./fonts/TitlingGothicLight.otf");
}

body {
  font-family: TitlingGothicMedium, San Francisco, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.arrow {
  border: solid #373737;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.hidden {
  display: none;
}
